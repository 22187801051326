import * as CANNON from 'cannon-es'
import gsap from 'gsap'
import * as THREE from 'three'
import { isMobile } from '../Utils/Utils'
import Deck from './Deck'

// TODO: create InteractiveObject abstract class
export default class Card {
  static THICKNESS = .06
  constructor(experience, id, deckType, textureName, backTextureName, width = 2, height = 3, depth = .04, position, rotationX, rotationZ, isFlipped = false) {
    this.experience = experience
    this.scene = this.experience.scene
    this.resourcesManager = this.experience.resourcesManager
    this.world = this.experience.world
    this.debug = this.experience.debug
    this.controls = this.experience.controls
    this.sizes = this.experience.sizes
    this.camera = this.experience.camera
    this.network = this.experience.network

    this.id = id
    this.deckType = deckType
    this.textureName = textureName
    this.backTextureName = backTextureName
    this.nbMoves = 0
    this.isFlipped = isFlipped
    this.isDragged = false
    this.draggerImage = null

    this.cardFlippedSfx = new Howl({ src: ['sounds/flip.mp3'], volume: 0.1, })

    this.initGeometry(width, height, depth)
    this.initTextures()
    this.initMesh(position)
    this.initPhysic(width, height, depth, position)

    if (rotationX) {
      this.rotateX(rotationX)
    }

    if (rotationZ) {
      this.rotateZ(rotationZ)
    }

    if (isFlipped) {
      this.flip(undefined, true)
    }

    if (this.mesh) {
      this.controls.addEventListener(this.mesh?.uuid, () => {
        if (!this.experience.playerId) {
          return
        }
        this.onSelect(this.experience.playerId)
      })
    }
  }

  initGeometry(width, height, depth) {
    this.geometry = new THREE.BoxGeometry(width, height, depth)
  }

  initTextures() {
    this.textures = {}
    this.textures.color = this.resourcesManager.items[this.textureName]
    this.textures.color.colorSpace = THREE.SRGBColorSpace
  }

  initMesh(position) {
    const backTexture = this.resourcesManager.items[this.backTextureName]
    if (backTexture) {
      backTexture.colorSpace = THREE.SRGBColorSpace

      this.mesh = new THREE.Mesh(this.geometry, [
        new THREE.MeshBasicMaterial(),
        new THREE.MeshBasicMaterial(),
        new THREE.MeshBasicMaterial(),
        new THREE.MeshBasicMaterial(),
        new THREE.MeshStandardMaterial({ map: this.textures.color }),
        new THREE.MeshBasicMaterial({map: backTexture}),
      ])
      this.mesh.castShadow = true
      this.mesh.position.copy(position)
      this.mesh.userData = {
        class: 'card'
      }
      this.scene.add(this.mesh)
    }
  }

  initPhysic(width, height, depth, initialPosition) {
    this.body = new CANNON.Body({
      mass: .1,
      position: new CANNON.Vec3(initialPosition.x, initialPosition.y, initialPosition.z),
      shape: new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5)),
      material: new CANNON.Material({
        friction: 10,
        restitution: 0, 
        contactEquationStiffness: 6000,
        contactEquationRelaxation: 0.000001
      }),
      collisionFilterGroup: 2,
      // sleepTimeLimit: 2.5
      // sleepSpeedLimit: .19
    })
    this.world.physicalWorld.addBody(this.body)

    this.body.addEventListener('sleep', async () => {
      if (this.experience.enableOnline && this.nbMoves > 0 && this.emitterId == this.experience.playerId) {
        const {x, y, z} = this.body.position
        await this.network.updateDocument('play_cards', this.id, { x, y, z })
      }
      this.nbMoves++

      if (this.debug.active) {
        console.log(this.isFaceUp())
      }
    })

    this.body.addEventListener('collide', (event) => {
      const collidedObject = this.experience.getInteractiveObjectFromBodyId(event.body.id)
      if (collidedObject instanceof Deck && collidedObject.type == this.type) {
        collidedObject.onCollide(this)
      }
    })
  }

  onDropped() {
    if (this.body) {
      this.body.applyImpulse(new CANNON.Vec3(.1 * 1 / 60, 0, 0))
    }
    this.cardFlippedSfx?.play()
  }

  flip(callBack, forced = false, emitterId) {
    if (this.body) {
      const tl = new gsap.timeline({
        defaults: {
          duration: .2,
          delay: 0.1
        },
        onComplete: async () => {
          this.rotateY(THREE.MathUtils.degToRad(180))
          this.body.applyImpulse(new CANNON.Vec3(0, 0, 0))
          if (!forced) {
            this.isFlipped = !this.isFlipped
            this.cardFlippedSfx.play()
            if (this.experience.enableOnline && emitterId == this.experience.playerId) {
              await this.network.updateDocument('play_cards', this.id, { isFlipped: this.isFlipped })
            }
          }
          callBack && callBack()
        },
      })
      tl.to(this.body.position, { y: 1.5 })
    }
  }

  isFaceUp() {
    const euler = new CANNON.Vec3()
    this.body.quaternion.toEuler(euler)
    let result

    if (euler.x >= 0) {
      result = 'down'
    } else {
      result = 'up'
    }
    return result == 'up'
  }

  isFaceDown() {
    return !this.isFaceUp()
  }

  rotateX(angleInRad) {
    this.mesh?.rotateX(angleInRad)
    this.body?.quaternion.copy(this.mesh.quaternion)
  }

  rotateY(angleInRad) {
    this.mesh?.rotateY(angleInRad)
    this.body?.quaternion.copy(this.mesh.quaternion)
  }

  async rotateZ(angleInRad, emitterId, callBack) {
    this.mesh?.rotateZ(angleInRad)
    this.body?.quaternion.copy(this.mesh.quaternion)
    this.nbMoves++

    if (this.experience.enableOnline && this.nbMoves > 0 && emitterId == this.experience.playerId) {
      const euler = new THREE.Euler()
      const rotation = euler.setFromQuaternion(this.mesh.quaternion)
      const radians = rotation.z > 0 ? rotation.z : 2 * Math.PI + rotation.z
      const degrees = THREE.MathUtils.radToDeg(radians)
      await this.network.updateDocument('play_cards', this.id, { rotationZ: Math.round(degrees) })
    }
    callBack && callBack()
  }

  async rotateLeft(emitterId, callBack) {
    if (this.isFaceUp()) {
      this.rotateZ(THREE.MathUtils.degToRad(45), emitterId, callBack)
    } else {
      this.rotateZ(THREE.MathUtils.degToRad(-45), emitterId, callBack)
    }
  }

  async rotateRight(emitterId, callBack) {
    if (this.isFaceUp()) {
      this.rotateZ(THREE.MathUtils.degToRad(-45), emitterId, callBack)
    } else {
      this.rotateZ(THREE.MathUtils.degToRad(45), emitterId, callBack)
    }
  }

  async reset(emitterId) {
    this.body.position.y = 1
    this.mesh.rotation.x = 0
    this.mesh.rotation.y = 0
    this.mesh.rotation.z = 0
    this.rotateX(THREE.MathUtils.degToRad(90))
    this.rotateY(THREE.MathUtils.degToRad(180))
    this.isFlipped = false
    this.body.quaternion.copy(this.mesh.quaternion)
    this.body.applyImpulse(new CANNON.Vec3(0, -.1, 0))
    if (this.experience.enableOnline && emitterId == this.experience.playerId) {
      const {x, y, z} = this.body.position
      await this.network.updateDocument('play_cards', this.id, { isFlipped: false, x, y, z})
    }
  }

  onSelect(emitterId) {
    // console.log(`Card ${this.id} selected by ${emitterId} ${emitterId === this.experience.playerId ? '(me)' : ''}`)
    if (isMobile()) {
      this.experience.currentObjectSelection = this.experience.INTERACTIVE_OBJECTS.find(obj => obj.id == this.id)
      this.experience.showObjectContextMenu(['btnRotateLeft', 'btnRotateRight', 'btnFlip'])
    }
  }

  moveTo(targetPosition, emitterId, callBack) {
    if (this.body) {
      this.emitterId = emitterId

      const tl = new gsap.timeline({
        defaults: {
          duration: 0.2,
          delay: 0.1
        },
        onComplete: () => {
          callBack && callBack()
        }
      })
      tl.to(this.body.position, {
        x: targetPosition.x,
        y: targetPosition.y,
        z: targetPosition.z
      })
    }
  }

  update() {
    if (this.mesh && this.body && this.toDelete !== true) {
      this.mesh.position.copy(this.body.position)
      this.mesh.quaternion.copy(this.body.quaternion)
    }
  }

  dispose(emitterId) {
    this.geometry.dispose()
    this.mesh.removeFromParent()
    this.experience.removeInteractiveObjectBy(this.id)
    if (this.experience.enableOnline && emitterId == this.experience.playerId) {
      // TODO delete from firebase
    }
  }
}