import * as CANNON from 'cannon-es'
import * as THREE from 'three'

export default class Floor {
  constructor(experience, id, boardTileTextureName) {
    this.id = id
    this.experience = experience
    this.scene = this.experience.scene
    this.resourcesManager = this.experience.resourcesManager
    this.physicalWorld = this.experience.world.physicalWorld

    this.initMeshes(boardTileTextureName)
    this.initPhysic()
  }

  initMeshes(tileTextureName) {
    this.textures = {}
    
    this.textures.color = this.resourcesManager.items[tileTextureName]
    this.textures.color.colorSpace = THREE.SRGBColorSpace

    const thickness = 0.05
    this.mesh = new THREE.Mesh(
      new THREE.BoxGeometry(20, thickness, 20),
      [
        new THREE.MeshLambertMaterial( {color: 'black'}),
        new THREE.MeshLambertMaterial( {color: 'black'}),
        new THREE.MeshStandardMaterial({
          map: this.textures.color,
          // normalMap: this.textures.normal
          }
        ),
        new THREE.MeshLambertMaterial( {color: 'black'}),
        new THREE.MeshLambertMaterial( {color: 'black'}),
        new THREE.MeshLambertMaterial( {color: 'black'})
      ]
    )
    this.mesh.receiveShadow = true
    this.scene.add(this.mesh)

    this.virtualFloorMesh = new THREE.Mesh(
      new THREE.BoxGeometry(30, thickness, 30, 64),
      new THREE.MeshStandardMaterial({
        transparent: true,
        opacity:0
        // wireframe: true,
        // color: 0xff0000
      })
    )
    this.scene.add(this.virtualFloorMesh)
  }

  initPhysic() {
    this.body = new CANNON.Body()
    this.body.mass = 0
    this.body.addShape(new CANNON.Plane())
    this.body.quaternion.setFromAxisAngle(new CANNON.Vec3(- 1, 0, 0), Math.PI * 0.5) 
    this.body.position.y = .001
    this.physicalWorld.addBody(this.body)
  }
}