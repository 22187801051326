import { Howl } from 'howler'
import * as THREE from 'three'
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js'
import { OutlinePass } from 'three/addons/postprocessing/OutlinePass.js'
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js'
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js'
import { CSS3DObject } from 'three/addons/renderers/CSS3DRenderer.js'
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer.js'
import * as packageJson from '../../package.json'
import assets from '../../static/assets'
import Camera from './Camera'
import Renderer from './Renderer'
import Controls from './Utils/Controls'
import Debug from './Utils/Debug'
import GoDice from './Utils/GoDice'
import NetworkService from './Utils/Network.js'
import ResourcesManager from './Utils/ResourcesManager'
import Sizes from './Utils/Sizes'
import Time from './Utils/Time'
import { isMobile } from './Utils/Utils.js'
import Card from './World/Card'
import World from './World/World'

export default class Experience extends EventTarget {
  constructor(canvasElement) {
    super()
    const urlParams = new URL(document.location).searchParams
    const playerId = urlParams.get('playerId')
    if (playerId) {
      localStorage.setItem('playerId', playerId)
    }
    this.playerId = localStorage.getItem('playerId')

    localStorage.setItem('version', packageJson.version)

    // Init props
    this.canvas = canvasElement
    this.debug = new Debug()
    this.sizes = new Sizes()
    this.time = new Time()
    this.scene = new THREE.Scene()
    this.uiScene = new THREE.Scene()
    this.raycaster = new THREE.Raycaster()
    this.network = new NetworkService(this)
    this.camera = new Camera(this)
    this.resourcesManager = new ResourcesManager(assets)
    this.controls = new Controls(this)
    this.renderer = new Renderer(this)
    this.uiRenderer = new CSS3DRenderer()
    this.uiRenderer.setSize(window.innerWidth, window.innerHeight)
    this.uiRenderer.domElement.style.position = 'absolute'
    this.uiRenderer.domElement.style.top = 0
    this.uiRenderer.domElement.style.zIndex = 0
    document.body.appendChild(this.uiRenderer.domElement)
    this.world = new World(this)

    this.debugEl = document.querySelector('.debug')

    this.INTERACTIVE_OBJECTS = []
    this.floorIntersect = null
    this.selectedObject = null
    this.currentObjectSelection = null
    this.hoveredObject = null
    this.isDragging = false

    this.music = new Howl({
      src: ['https://res.cloudinary.com/raphkun-learn/video/upload/v1731273348/f996nxjwgx7cp0gzrfjq.mp3'],
      autoplay: false,
      loop: true,
      volume: 0.5
    })
    this.alertSound = new Howl({ src: ['sounds/alert.mp3'] })

    this.sizes.addEventListener('resize', () => {
      this.resize()
    })

    this.time.addEventListener('tick', this.update.bind(this))

    this.controls.addEventListener('keyup_KeyI', () => {
      console.log(this.hoveredObject);
    })
    this.controls.addEventListener('keyup_KeyQ', () => {
      if (typeof this.hoveredObject?.rotateLeft == 'function') {
        this.hoveredObject?.rotateLeft(this.playerId)
        this.network.broadcastEvent({ name: 'keyup_q', id: this.hoveredObject?.id })
      }
    })
    this.controls.addEventListener('keyup_KeyE', () => {
      if (typeof this.hoveredObject?.rotateLeft == 'function') {
        this.hoveredObject?.rotateRight(this.playerId)
      }
      this.network.broadcastEvent({ name: 'keyup_e', id: this.hoveredObject?.id })
    })
    this.controls.addEventListener('keyup_KeyR', () => {
      if (this.hoveredObject?.reset) {
        this.hoveredObject.reset(this.playerId)
        this.network.broadcastEvent({ name: 'reset', id: this.hoveredObject?.id })
      }
    })
    this.controls.addEventListener('keyup_KeyF', () => {
      if (this.hoveredObject?.flip) {
        this.hoveredObject.flip(undefined, false, this.playerId)
        this.network.broadcastEvent({ name: 'flip', id: this.hoveredObject?.id })
      }
    })
    this.controls.addEventListener('keyup_Space', () => {
      this.focusToPlayer()
    })
    this.controls.addEventListener('floorClicked', (event) => {
      if (isMobile() && this.currentObjectSelection) {
        const objectToMove = this.INTERACTIVE_OBJECTS.find(obj => obj.id == this.currentObjectSelection.id)
        const elevatedPosition = new THREE.Vector3(event.detail.position.x, 1.2, event.detail.position.z)
        objectToMove?.moveTo(elevatedPosition, this.playerId, () => {
          objectToMove?.onDropped && objectToMove?.onDropped()
          this.network.broadcastEvent({ name: 'objectDropped', id: objectToMove?.id })
        })
        this.network.broadcastEvent({ name: 'objectDragging', id: objectToMove?.id, ...elevatedPosition })
      }
    })
    this.controls.addEventListener('objectDragging', (event) => {
      const position = event.detail.position
      if (this.selectedObject && position) {
        const elevatedPosition = new THREE.Vector3(position.x, 1.2, position.z)
        if (this.selectedObject.moveTo && this.selectedObject.draggable !== false) {
          this.selectedObject.moveTo(elevatedPosition, this.playerId)
          this.network.broadcastEvent({ name: 'objectDragging', id: this.selectedObject?.id, ...elevatedPosition })
        }
      }
    })
    this.controls.addEventListener('objectDropped', (event) => {
      const object = this.INTERACTIVE_OBJECTS.find(obj => obj.id == event?.detail?.objectId)
      if (object?.onDropped) {
        object.onDropped()
        this.network.broadcastEvent({ name: 'objectDropped', id: this.selectedObject?.id })
      }
      this.selectedObject = null
    })

    document.getElementById('btnRotateLeft').addEventListener('click', () => {
      if (typeof this.currentObjectSelection?.rotateLeft == 'function') {
        this.currentObjectSelection.rotateLeft(this.playerId)
        this.network.broadcastEvent({ name: 'keyup_q', id: this.currentObjectSelection?.id })
      }
    })

    document.getElementById('btnRotateRight').addEventListener('click', () => {
      if (typeof this.currentObjectSelection?.rotateLeft == 'function') {
        this.currentObjectSelection.rotateRight(this.playerId)
        this.network.broadcastEvent({ name: 'keyup_e', id: this.currentObjectSelection?.id })
      }
    })

    document.getElementById('btnFlip').addEventListener('click', () => {
      if (typeof this.currentObjectSelection?.flip == 'function') {
        this.currentObjectSelection.flip(null, false, this.playerId)
        this.network.broadcastEvent({ name: 'flip', id: this.currentObjectSelection?.id })
      }
    })

    window.onbeforeunload = () => {
      this.network.updateDocument('play_users', this.playerId, {})
    }

    this.initGoDice()

    this.initPostProcessing()
    
    // Network
    let enableOnline = urlParams.get('enableOnline')
    if (enableOnline) {
      localStorage.setItem('enableOnline', enableOnline)
    }
    if (!this.playerId) {
      localStorage.setItem('enableOnline', false)
    }
    this.enableOnline = typeof localStorage.getItem('enableOnline') == 'string' ? localStorage.getItem('enableOnline') === 'true' : true
    this.players = {}
    if (this.enableOnline) {
      this.network.fetchDocuments('play_users').then((docs) => {
        docs.forEach((doc, i) => {
          this.players[doc.id] = doc
          this.createPlayerAvatar(doc.id, doc)
          let pos = {}
          if (i < 4) {
            pos = this.world.spawnPositions[i]
            this.players[doc.id].spawnPosition = pos
          }
          this.createPlayerZone(doc, pos)
        })
        this.createCurrentPlayerButton(this.players[this.playerId])
      })
      this.network.initOnline()
    }

    // UI
    const html = document.querySelector('html')
    if (isMobile()) {
      html.classList.add('mobile')
    } else {
      html.classList.add('desktop')
    }
  }

  // TODO: move to UIService
  createPlayerAvatar(playerId, player) {
    if (!player) {
      return
    }
    
    const avatarDiv = document.createElement('div')
    avatarDiv.id = playerId
    avatarDiv.classList.add('avatar', playerId)
    
    const labelDiv = document.createElement('div')
    labelDiv.classList.add('label')
    if (player.imageUrl) {
      labelDiv.style.backgroundImage = `url('${player.imageUrl}')`
    } else {
      labelDiv.innerHTML = player.username?.[0]
    }
    avatarDiv.appendChild(labelDiv)
    document.body.append(avatarDiv)
  }

  createPlayerZone(player, position) {
    const element = document.createElement('div')
    element.id = `player-board-${player.id}`
    element.classList.add('player-board')
    element.innerHTML = `
      <img class="player-avatar" src='${player?.imageUrl || 'https://i.pravatar.cc/300'}' />
      <span class="text">${player?.username || 'guest'}</span>
    `

    var object = new CSS3DObject(element)
    object.position.x = position?.x || 0
    object.position.y = position?.y || 0
    object.position.z = position?.z || 0
    object.rotation.x = position?.rotation?.x || 0
    object.rotation.z = position?.rotation?.z || 0
    const scale = .07
    object.scale.x = scale
    object.scale.y = scale
    this.uiScene.add(object)
  }

  createCurrentPlayerButton(player) {
    const avatarNavItem = document.createElement('i')
    avatarNavItem.classList.add('current-player')
    avatarNavItem.innerHTML = `
      <img class="player-avatar" src='${player?.imageUrl || 'https://i.pravatar.cc/300'}' />
      <span>${player?.username || 'guest'}</span>
      `
    const bottomLeftNav = document.querySelector('nav.bottom-left')
    bottomLeftNav.appendChild(avatarNavItem)
    avatarNavItem.addEventListener('click', this.focusToPlayer.bind(this, player?.id))
  }

  showObjectContextMenu(actions) {
    actions.forEach(action => {
      document.getElementById(action)?.classList.remove('hidden')
    });
  }

  hideContextMenu() {
    document.querySelectorAll('.context').forEach(el => el.classList.add('hidden'))
  }

  focusToPlayer(playerId) {
    playerId = playerId || this.playerId
    const player = this.players[playerId]
    if (player?.spawnPosition) {
      this.camera.animateTo((player.spawnPosition.x * 2.5), this.camera.instance.position.y, (player.spawnPosition.z * 2.5))
      this.camera.controls.target.set(0,0,0)
    }
  }

  initPostProcessing() {
    // post-processing
    this.composer = new EffectComposer( this.renderer.instance )

    const renderPass = new RenderPass( this.scene, this.camera.instance )
    this.composer.addPass( renderPass )

    this.hoverOutlinePass = new OutlinePass( new THREE.Vector2( this.sizes.width, this.sizes.height ), this.scene, this.camera.instance )
    this.hoverOutlinePass.edgeStrength = 3
    this.hoverOutlinePass.edgeGlow = 0.0
    this.hoverOutlinePass.edgeThickness = 1
    this.hoverOutlinePass.pulsePeriod = 0
    this.hoverOutlinePass.visibleEdgeColor.set('#ffffff')
    this.hoverOutlinePass.hiddenEdgeColor.set('#190a05')
    this.composer.addPass(this.hoverOutlinePass)

    this.selectedOutlinePass = new OutlinePass( new THREE.Vector2( this.sizes.width, this.sizes.height ), this.scene, this.camera.instance )
    this.selectedOutlinePass.edgeStrength = 6
    this.selectedOutlinePass.edgeGlow = 0.8
    this.selectedOutlinePass.edgeThickness = 2
    this.selectedOutlinePass.pulsePeriod = 0
    this.selectedOutlinePass.visibleEdgeColor.set('#FFFF00')
    this.selectedOutlinePass.hiddenEdgeColor.set('#190a05')
    this.composer.addPass(this.selectedOutlinePass)

    const outputPass = new OutputPass()
    this.composer.addPass(outputPass)
  }

  getInteractiveObjectFromUuids(uuids) {
    let firstMatchingObject = null
    let remainingObjectsToSearch = this.INTERACTIVE_OBJECTS.length
    while (firstMatchingObject == null && remainingObjectsToSearch > 0) {
      if (this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1].model) {
        this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1].model?.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            if (uuids.indexOf(child.uuid) >= 0) {
              firstMatchingObject = this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1]
            }
          }
        })
      } else {
        if (uuids.indexOf(this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1].mesh?.uuid) >= 0) {
          firstMatchingObject = this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1]
        } else {
          if (uuids.indexOf(this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1].uuid) >= 0) {
            firstMatchingObject = this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1]
          }
        }
      }
      remainingObjectsToSearch--
    }
    return firstMatchingObject
  }

  getInteractiveObjectFromBodyId(bodyId) {
    return this.INTERACTIVE_OBJECTS.find(obj => obj.body?.id == bodyId)
  }

  getInteractiveObjectFromFirebaseId(firebaseId) {
    let firstMatchingObject = null
    let remainingObjectsToSearch = this.INTERACTIVE_OBJECTS.length
    while (firstMatchingObject == null && remainingObjectsToSearch > 0) {
      if (this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1].id == firebaseId) {
        firstMatchingObject = this.INTERACTIVE_OBJECTS[remainingObjectsToSearch - 1]
      }
      remainingObjectsToSearch--
    }
    return firstMatchingObject
  }

  removeInteractiveObjectBy(id) {
    const idx = this.INTERACTIVE_OBJECTS.findIndex(obj => obj.id == id)
    if (idx >= 0) {
      this.INTERACTIVE_OBJECTS[idx].toDelete = true
    }
  }

  instanciateInteractiveObject(type, props, targetPosition) {
    let elevatedPosition = new THREE.Vector3(0, 3, 0)
    const playerZoneCenter = targetPosition || new THREE.Vector3(0, 1, 0)
    switch(type) {
      case 'Card':
        const card = new Card(this, props.id, props.deckType, props.textureName, props.backTextureName, undefined, undefined, undefined, elevatedPosition, THREE.MathUtils.degToRad(90))
        this.INTERACTIVE_OBJECTS.push(card)
        card.moveTo(playerZoneCenter, this.playerId, ()=> { card.onDropped() })
        break
    }
  }

  initGoDice() {
    GoDice.prototype.onDiceConnected = (diceId, diceInstance) => {
      // console.log('Dice connected: ', diceId, diceInstance.GoDiceService.device.name)
      this.alertSound.play()
    }
    
    GoDice.prototype.onRollStart = (diceId) => {
      console.log('Roll Start: ', diceId)
    }
    
    GoDice.prototype.onStable = (diceId, value, xyzArray) => {
      console.log('Stable event: ', diceId, value)
      this.controls.dispatchEvent(new Event('rolled'))
    }
    
    GoDice.prototype.onTiltStable = (diceId, xyzArray, value) => {
      console.log('TiltStable: ', diceId, xyzArray)
    }
    
    GoDice.prototype.onFakeStable = (diceId, value, xyzArray) => {
      console.log('FakeStable: ', diceId, value)
    }
    
    GoDice.prototype.onMoveStable = (diceId, value, xyzArray) => {
      console.log('MoveStable: ', diceId, value)
    }
    
    GoDice.prototype.onBatteryLevel = (diceId, batteryLevel) => {
      console.log('BatteryLevel: ', diceId, batteryLevel)
    }
    
    GoDice.prototype.onDiceColor = (diceId, color) => {
      console.log('DiceColor: ', diceId, color)
    }
  }

  openBluetoothConnectionDialog() {
    const newDice = new GoDice()
    newDice.requestDevice()
  }

  toggleFullScreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
    } else if (document.exitFullscreen) {
        document.exitFullscreen()
    }
  }

  toggleMusic() {
    this.music.playing() ? this.music.pause() : this.music.play()
  }

  resize() {
    this.camera.resize()
    this.renderer.resize()
    this.uiRenderer.setSize(window.innerWidth, window.innerHeight)
  }

  update() {
    this.camera.update()
    this.world.update()
    this.renderer.update()
    this.uiRenderer.render(this.uiScene, this.camera.instance)
    this.composer.render()
  }

  destroy() {
    this.sizes.removeEventListener('resize')
    this.time.removeEventListener('tick')
    this.scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose()
        for (const key in child.material) {
          const value = child.material[key]

          if (value && typeof value.dispose == 'function') [
            value.dispose()
          ]
        }
      }
    })
    this.camera.controls.dispose()
    this.renderer.instance.clear()
    this.renderer.instance.dispose()

    if (this.debug.active) {
      this.debug.gui.destroy()
    }
  }
}