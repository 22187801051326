import * as CANNON from 'cannon-es'
import gsap from 'gsap'
import * as THREE from 'three'
import { uuidv4 } from '../Utils/Utils'
import Card from './Card'

// TODO: create InteractiveObject abstract class
export default class Deck {
  constructor(experience, id, textureName, deckType, cards = [], isLocked = false, position, rotationY) {
    this.experience = experience
    this.scene = this.experience.scene
    this.resourcesManager = this.experience.resourcesManager
    this.world = this.experience.world
    this.debug = this.experience.debug
    this.controls = this.experience.controls
    this.network = this.experience.network

    this.id = id
    this.isLocked = isLocked
    this.deckType = deckType
    this.cards = cards
    this.label = cards?.length?.toString()
    this.nbMoves = 0

    this.initTextures(textureName)
    this.initModel(position)
    this.initPhysic(position)

    if (rotationY) {
      this.initialRotationY = rotationY
      this.rotateY(rotationY)
    }

    if (this.model) {
      this.controls.addEventListener(this.model?.uuid, () => {
        if (!this.experience.playerId) {
          return
        }
        this.onSelect(this.experience.playerId)
      })
    }
  }

  initTextures(textureName) {
    this.textures = {}
    this.textures.color = this.resourcesManager.items[textureName]
    this.textures.color.colorSpace = THREE.SRGBColorSpace
  }

  initModel(initialPosition) {
    this.model = this.resourcesManager.items.deckModel.scene.clone()
    if (this.model.userData?.scale) {
      this.model.scale.set(this.model.userData?.scale, this.model.userData?.scale, this.model.userData?.scale)
    }
    const position = initialPosition || new THREE.Vector3(0,0,0)
    this.model.position.set(position.x, position.y, position.z)
    this.refreshLabel()
    this.scene.add(this.model)

    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true
        if (child.name.indexOf('deck-face') >= 0) {
          child.material = new THREE.MeshStandardMaterial({ map: this.textures.color }),
          child.material.map.needsUpdate = true
        }
      }
    })

    this.model.helperBox = new THREE.BoxHelper(this.model, 0xffff00)
    this.model.helperBox.geometry.computeBoundingBox()
    if (this.debug.active) {
      this.scene.add(this.model.helperBox)
    }
  }

  updateTexture(backTextureName) {
    this.textures.color = this.resourcesManager.items[backTextureName]
    this.textures.color.colorSpace = THREE.SRGBColorSpace
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true
        if (child.name.indexOf('deck-face') >= 0) {
          child.material = new THREE.MeshStandardMaterial({ map: this.textures.color }),
          child.material.map.needsUpdate = true
        }
      }
    })
  }

  initPhysic(pos) {
    const max = this.model.helperBox.geometry.boundingBox.max
    const min = this.model.helperBox.geometry.boundingBox.min
    const width = max.x - min.x
    const height = max.y - min.y
    const depth = max.z - min.z
    const shape = new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5))
    const material = new CANNON.Material({
      friction: 10,
      restitution: 0, 
      contactEquationStiffness: 6000,
      contactEquationRelaxation: 0.000001
    })

    this.body = new CANNON.Body({
        mass: 1000,
        position: new CANNON.Vec3(pos.x, pos.y, pos.z),
        shape: shape,
        material,
        collisionFilterGroup: 2
    })
    this.world.physicalWorld.addBody(this.body)

    this.body.addEventListener('sleep', async () => {
      if (this.experience.enableOnline && this.nbMoves > 0 && this.emitterId == this.experience.playerId) {
        const {x, y, z} = this.body.position
        await this.network.updateDocument('play_decks', this.id, { x, y, z })
      }

      this.nbMoves++
    })

    this.body.addEventListener('collide', (event) => {
      const collidedObject = this.experience.getInteractiveObjectFromBodyId(event.body.id)
      if (collidedObject instanceof Card && collidedObject.deckType == this.deckType) {
        this.onCollide(collidedObject)
      }
    })
  }

  async rotateY(angleInRad, emitterId) {
    if (this.isLocked && emitterId) {
      return
    }
    this.model.rotateY(angleInRad)
    this.body.quaternion.copy(this.model.quaternion)

    if (this.experience.enableOnline && this.nbMoves > 0 && emitterId == this.experience.playerId) {
      const euler = new THREE.Euler()
      const rotation = euler.setFromQuaternion(this.model.quaternion)
      const radians = rotation.y > 0 ? rotation.y : 2 * Math.PI + rotation.y
      const degrees = THREE.MathUtils.radToDeg(radians)
      await this.network.updateDocument('play_decks', this.id, { rotationY: Math.round(degrees) })
    }
    this.nbMoves++
  }

  async rotateLeft(emitterId) {
    this.rotateY(THREE.MathUtils.degToRad(45), emitterId)
  }

  async rotateRight(emitterId) {
    this.rotateY(THREE.MathUtils.degToRad(-45), emitterId)
  }

  onSelect(emitterId) {
    // console.log(`Deck ${this.id} selected by ${emitterId} ${emitterId === this.experience.playerId ? '(me)' : ''}`)
    const emitterSpawnPosition = this.experience.players[emitterId]?.spawnPosition || {x: 5, y: 1, z: -5}
    this.deal(emitterSpawnPosition)
  }

  deal(targetPosition) {
    if (this.cards?.length) {
      const card = this.cards.pop()
      this.cards = this.cards.filter(c => c.textureName != card.textureName)
      this.refreshLabel()
      const spawnPosition = new THREE.Vector3(targetPosition?.x || 0, targetPosition?.y || 0, targetPosition?.z || 0)
      this.experience.instanciateInteractiveObject('Card', {...card, id: uuidv4()}, spawnPosition)
      if (this.cards?.length) {
        this.updateTexture(this.cards[this.cards.length - 1].backTextureName)
      }
    }
  }

  moveTo(targetPosition, emitterId) {
    if (this.isLocked  && emitterId) {
      return
    }
    const tl = new gsap.timeline({
      defaults: {
        duration: 0.22,
        delay: 0.1,
        onComplete: () => {
          this.body.applyImpulse(new CANNON.Vec3(0, 0, 0))
        }
      }
    })
    tl.to(this.body.position, {
      x: targetPosition.x,
      y: targetPosition.y,
      z: targetPosition.z
    })

    this.emitterId = emitterId
  }

  reset(emitterId) {
    if (this.isLocked && emitterId) {
      return
    }
    this.body.position.y = 1
    this.model.rotation.x = 0
    this.model.rotation.y = 0
    this.model.rotation.z = 0
    if (this.initialRotationY) {
      this.rotateY(this.initialRotationY, emitterId)
    }
    this.body.applyImpulse(new CANNON.Vec3(0, 0, 0))
  }

  onCollide(droppedObject) {
    if (droppedObject.deckType == this.deckType && droppedObject.toDelete !== true) {
      if (!this.cards.map(({id})=>id).includes(droppedObject.id)) {
        droppedObject?.dispose()
        this.cards.push({
          id: droppedObject.id,
          deckType: droppedObject.deckType,
          textureName: droppedObject.textureName,
          backTextureName: droppedObject.backTextureName
        })
        this.updateTexture(this.cards[this.cards.length - 1].backTextureName)
      }
      // TODO save in firebase
      this.refreshLabel()
    }
  }

  refreshLabel() {
    this.label = this.cards?.length.toString()
    this.model.scale.y = this.cards?.length ? Card.THICKNESS * this.cards?.length : 0
    this.model.visible = this.cards?.length > 0
  }

  update() {
    this.model.position.copy(this.body.position)
    this.model.quaternion.copy(this.body.quaternion)
    this.model.helperBox.update()
  }
}