import * as CANNON from 'cannon-es'
import { Howl } from 'howler'
import * as THREE from 'three'
import { isHalfPi, isMinusHalfPi, isPiOrMinusPi, isZero, rand } from '../Utils/Utils'

export default class Dice extends EventTarget {
  constructor(experience, id, modelName, startingPosition) {
    super()
    this.experience = experience
    this.scene = this.experience.scene
    this.resourcesManager = this.experience.resourcesManager
    this.world = this.experience.world
    this.debug = this.experience.debug
    this.controls = this.experience.controls
    this.network = this.experience.network

    this.diceThrownSound = new Howl({ src: ['sounds/dice.mp3'], volume: 0.33 })
    this.nbRoll = 0
    this.id = id

    this.initModel(modelName, startingPosition)
    this.initPhysic()
    this.controls.addEventListener(this.model.uuid, () => {
      const randomParam = {
        angle: Math.round(rand(0, 361)) * (Math.PI/180),
        x: rand(-3, 3),
        y: rand(2, 5),
        z: rand(-3, 3)
      }
      this.roll(randomParam.angle, randomParam.x, randomParam.y, randomParam.z, this.experience.playerId)
      if (!this.experience.playerId) {
        return
      }
      this.network.broadcastEvent({ name: 'rollDice', id: this.id, ...randomParam, emitterId: this.experience.playerId })
    })
  }

  initModel(modelName, startingPosition) {
    this.model = this.resourcesManager.items[modelName].scene.clone()
    const position = startingPosition ? startingPosition : new THREE.Vector3(0,0,0)
    if (this.model.userData?.scale) {
      this.model.scale.set(this.model.userData?.scale, this.model.userData?.scale, this.model.userData?.scale)
    }
    this.model.position.set(position.x, position.y, position.z)
    this.scene.add(this.model)
    
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true
      }
    })
    this.model.helperBox = new THREE.BoxHelper(this.model, 0xffff00)
    this.model.helperBox.geometry.computeBoundingBox()

    if (this.debug.active) {
      this.scene.add(this.model.helperBox)
    }
  }

  initPhysic(position) {
    const startingPosition = position || this.model.position || new THREE.Vector3(0,0,0)
    const max = this.model.helperBox.geometry.boundingBox.max
    const min = this.model.helperBox.geometry.boundingBox.min
    const height = max.z - min.z
    const width = max.x - min.x
    const depth = max.y - min.y
    
    const shape = new CANNON.Box(new CANNON.Vec3(width * 0.5, height * 0.5, depth * 0.5))

    this.body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(startingPosition.x, startingPosition.y, startingPosition.z),
        shape: shape,
        material: this.world.physicalWorld.defaultMaterial
    })
    this.world.physicalWorld.addBody(this.body)

    this.body.addEventListener('sleep', (e) => {
      this.onRolled()
    })
  }

  roll(angle, x, y, z, emitterId) {
    if (this.body) {
      this.diceThrownSound.play()
      angle = angle || Math.round(rand(0, 361)) * (Math.PI/180)
      this.body.quaternion.setFromAxisAngle(new CANNON.Vec3(1,1,0), angle)
      x = x || rand(-3, 3)
      y = y || rand(2, 4)
      z = z || rand(-3, 3)
      this.body.position.set(x, y, z)
      this.body.applyImpulse(new CANNON.Vec3(0, -3 * 1/60, 0), new CANNON.Vec3(0, 0, 0))
      this.emitterId = emitterId
      this.nbRoll++
    }
  }

  onRolled() {
    let result = this.getResult()
    this.label = result
    if (result && this.nbRoll > 0) {
      this.dispatchEvent(new CustomEvent('rolled'))
    } else if (this.body && this.nbRoll > 0) {
      this.label = null
      const impulse = .2 * 1/60
      this.body.applyImpulse(new CANNON.Vec3(impulse * rand(0, 2), impulse * rand(0, 2), impulse * rand(0, 2)))
    }
  }

  rotateTo(number) {
    let x = 0
    let y = 0
    let z = 0
    switch(number) {
      case 1:
        x = -Math.PI/2
        break
      case 2:
        x = Math.PI/2
        break
      case 3:
        z = Math.PI/2
        break
      case 4:
        x = Math.PI
        break
      case 5:
        z = -Math.PI/2
        break
      case 6:
        break
    }
    this.body.quaternion.setFromEuler(x, y, z)
  }

  getResult() {
    const euler = new CANNON.Vec3()
    this.body.quaternion.toEuler(euler)

    let result
    if (isZero(euler.z)) {
      if (isZero(euler.x)) {
        result = 6
      } else if (isHalfPi(euler.x)) {
        result = 2
      } else if (isMinusHalfPi(euler.x)) {
        result = 1
      } else if (isPiOrMinusPi(euler.x)) {
        result = 4
      }
    } else if (isHalfPi(euler.z)) {
      result = 3
    } else if (isMinusHalfPi(euler.z)) {
      result = 5
    }
    return result
  }

  update() {
    if (this.body) {
      this.model.position.copy(this.body.position)
      this.model.quaternion.copy(this.body.quaternion)
    }
  }
}