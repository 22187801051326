export default {
  objects: [
    {id: 'carToken', type:'Piece', modelName: 'carModel', position: { x: 7.8, y: 1, z: 8 }},
    {id: 'hatToken', type:'Piece', modelName: 'hatModel', position: { x: 9, y: 1, z: 8 }},
    {id: 'shipToken', type:'Piece', modelName: 'shipModel', position: { x: 9, y: 1, z: 9 }},
    {id: 'ironToken', type:'Piece', modelName: 'ironModel', position: { x: 7.8, y: 1, z: 9 }},
    {id: 'dice1', type:'Dice', modelName: 'diceModel', position: { x: 0, y: 0, z: 0 }},
    {id: 'dice2', type:'Dice', modelName: 'diceModel', position: { x: 1, y: 0, z: 1 }},
    {id: 'hotel1', type: 'Piece', modelName: 'hotelModel', position: { x: 0, y: 1, z: 5 }},
    {id: 'house1', type: 'Piece', modelName: 'houseModel', position: { x: 0, y: 1, z: 6 }},
    {id: 'chanceCardsDeck', type: 'Deck', textureName: 'chanceBackTexture', deckType: 'chance', cards: [
      {id: 'chance_ATB', type: 'Card', deckType: 'chance', textureName:'chance_ATB', backTextureName: 'chanceBackTexture'},
      {id: 'chance_ATGC200', type: 'Card', deckType: 'chance', textureName:'chance_ATGC200', backTextureName: 'chanceBackTexture'},
      {id: 'chance_ATIA', type: 'Card', deckType: 'chance', textureName:'chance_ATIA', backTextureName: 'chanceBackTexture'},
      {id: 'chance_ATSCP', type: 'Card', deckType: 'chance', textureName:'chance_ATSCP', backTextureName: 'chanceBackTexture'},
      {id: 'chance_ATTNRR', type: 'Card', deckType: 'chance', textureName:'chance_ATTNRR', backTextureName: 'chanceBackTexture'},
      {id: 'chance_ATTNU', type: 'Card', deckType: 'chance', textureName:'chance_ATTNU', backTextureName: 'chanceBackTexture'},
      {id: 'chance_BPYD50', type: 'Card', deckType: 'chance', textureName:'chance_BPYD50', backTextureName: 'chanceBackTexture'},
      {id: 'chance_GB3S', type: 'Card', deckType: 'chance', textureName:'chance_GB3S', backTextureName: 'chanceBackTexture'},
      {id: 'chance_GOOJF', type: 'Card', deckType: 'chance', textureName:'chance_GOOJF', backTextureName: 'chanceBackTexture'},
      {id: 'chance_GTJ', type: 'Card', deckType: 'chance', textureName:'chance_GTJ', backTextureName: 'chanceBackTexture'},
      {id: 'chance_MGR25PH100PH', type: 'Card', deckType: 'chance', textureName:'chance_MGR25PH100PH', backTextureName: 'chanceBackTexture'},
      {id: 'chance_PPT15', type: 'Card', deckType: 'chance', textureName:'chance_PPT15', backTextureName: 'chanceBackTexture'},
      {id: 'chance_TAROTRC200', type: 'Card', deckType: 'chance', textureName:'chance_TAROTRC200', backTextureName: 'chanceBackTexture'},
      {id: 'chance_YBALMC150', type: 'Card', deckType: 'chance', textureName:'chance_YBALMC150', backTextureName: 'chanceBackTexture'},
      {id: 'chance_YHBAECOTBPEP50', type: 'Card', deckType: 'chance', textureName:'chance_YHBAECOTBPEP50', backTextureName: 'chanceBackTexture'}
    ], isLocked: true, position: { x: 4.3, y: 1, z: 4.4 }, rotationY: 135},
    {id: 'communityCardsDeck', type: 'Deck', textureName: 'communityCoverTexture', deckType: 'community', cards: [
      {id: 'community_ATGC200', type: 'Card', deckType: 'community', textureName:'community_ATGC200', backTextureName: 'communityCoverTexture'},
      {id: 'community_BEIYFC200', type: 'Card', deckType: 'community', textureName:'community_BEIYFC200', backTextureName: 'communityCoverTexture'},
      {id: 'community_DFP50', type: 'Card', deckType: 'community', textureName:'community_DFP50', backTextureName: 'communityCoverTexture'},
      {id: 'community_FSOSYG45', type: 'Card', deckType: 'community', textureName:'community_FSOSYG45', backTextureName: 'communityCoverTexture'},
      {id: 'community_GOOC50FEP', type: 'Card', deckType: 'community', textureName:'community_GOOC50FEP', backTextureName: 'communityCoverTexture'},
      {id: 'community_GOOJF', type: 'Card', deckType: 'community', textureName:'community_GOOJF', backTextureName: 'communityCoverTexture'},
      {id: 'community_GTJ', type: 'Card', deckType: 'community', textureName:'community_GTJ', backTextureName: 'communityCoverTexture'},
      {id: 'community_ITRC20', type: 'Card', deckType: 'community', textureName:'community_ITRC20', backTextureName: 'communityCoverTexture'},
      {id: 'community_LIMC100', type: 'Card', deckType: 'community', textureName:'community_LIMC100', backTextureName: 'communityCoverTexture'},
      {id: 'community_PH100', type: 'Card', deckType: 'community', textureName:'community_PH100', backTextureName: 'communityCoverTexture'},
      {id: 'community_PST150', type: 'Card', deckType: 'community', textureName:'community_PST150', backTextureName: 'communityCoverTexture'},
      {id: 'community_RFS25', type: 'Card', deckType: 'community', textureName:'community_RFS25', backTextureName: 'communityCoverTexture'},
      {id: 'community_XFMC100', type: 'Card', deckType: 'community', textureName:'community_XFMC100', backTextureName: 'communityCoverTexture'},
      {id: 'community_YAAFSR40PH115PH', type: 'Card', deckType: 'community', textureName:'community_YAAFSR40PH115PH', backTextureName: 'communityCoverTexture'},
      {id: 'community_YHWSPIABCC10', type: 'Card', deckType: 'community', textureName:'community_YHWSPIABCC10', backTextureName: 'communityCoverTexture'},
      {id: 'community_YI100', type: 'Card', deckType: 'community', textureName:'community_YI100', backTextureName: 'communityCoverTexture'},
    ], isLocked: true, position: { x: -4.3, y: 1, z: -4.4 }, rotationY: -45},
    {id: 'deedCardsDeck', type: 'Deck', textureName: 'utilityCard2BackTexture', deckType: 'deed', cards: [
      {id: 'deed1', type: 'Card', deckType: 'deed', textureName:'deedCard1Texture', backTextureName: 'deedCard1BackTexture'/* , position: { x: 6, y: 0, z: 11.8 }, rotationX: -90, rotationZ: 0, isFlipped: false */},
      {id: 'deed2', type: 'Card', deckType: 'deed', textureName:'deedCard2Texture', backTextureName: 'deedCard2BackTexture'/* , position: { x: 3, y: 0, z: 11.8 }, rotationX: -90, rotationZ: 0, isFlipped: false */},
      {id: 'deed3', type: 'Card', deckType: 'deed', textureName:'deedCard3Texture', backTextureName: 'deedCard3BackTexture', position: { x: -1.7, y: 0, z: 11.8 }, rotationX: -90, rotationZ: 0, isFlipped: false},
      {id: 'deed4', type: 'Card', deckType: 'deed', textureName:'deedCard4Texture', backTextureName: 'deedCard4BackTexture', position: { x: -4.5, y: 0, z: 11.8 }, rotationX: -90, rotationZ: 0, isFlipped: false},
      {id: 'deed5', type: 'Card', deckType: 'deed', textureName:'deedCard5Texture', backTextureName: 'deedCard5BackTexture', position: { x: -6.7, y: 0, z: 11.8 }, rotationX: -90, rotationZ: 0, isFlipped: false},
      {id: 'deed6', type: 'Card', deckType: 'deed', textureName:'deedCard6Texture', backTextureName: 'deedCard6BackTexture', position: { x: -11.7, y: 0, z: 6.5 }, rotationX: -90, rotationZ: -90, isFlipped: false},
      {id: 'deed7', type: 'Card', deckType: 'deed', textureName:'deedCard7Texture', backTextureName: 'deedCard7BackTexture', position: { x: -11.7, y: 0, z: 3.5 }, rotationX: -90, rotationZ: -90, isFlipped: false},
      {id: 'deed8', type: 'Card', deckType: 'deed', textureName:'deedCard8Texture', backTextureName: 'deedCard8BackTexture', position: { x: -11.7, y: 0, z: 1.25 }, rotationX: -90, rotationZ: -90, isFlipped: false},
      {id: 'deed9', type: 'Card', deckType: 'deed', textureName:'deedCard9Texture', backTextureName: 'deedCard9BackTexture', position: { x: -11.7, y: 0, z: -1.5 }, rotationX: -90, rotationZ: -90, isFlipped: false},
      {id: 'deed10', type: 'Card', deckType: 'deed', textureName:'deedCard10Texture', backTextureName: 'deedCard10BackTexture', position: { x: -11.7, y: 0, z: -4.5 }, rotationX: -90, rotationZ: -90, isFlipped: false},
      {id: 'deed11', type: 'Card', deckType: 'deed', textureName:'deedCard11Texture', backTextureName: 'deedCard11BackTexture', position: { x: -11.7, y: 0, z: -6.7 }, rotationX: -90, rotationZ: -90, isFlipped: false},
      {id: 'deed12', type: 'Card', deckType: 'deed', textureName:'deedCard12Texture', backTextureName: 'deedCard12BackTexture', position: { x: -6.5, y: 0, z: -11.8 }, rotationX: -90, rotationZ: 180, isFlipped: false},
      {id: 'deed13', type: 'Card', deckType: 'deed', textureName:'deedCard13Texture', backTextureName: 'deedCard13BackTexture', position: { x: -3.5, y: 0, z: -11.8 }, rotationX: -90, rotationZ: 180, isFlipped: false},
      {id: 'deed14', type: 'Card', deckType: 'deed', textureName:'deedCard14Texture', backTextureName: 'deedCard14BackTexture', position: { x: -1.3, y: 0, z: -11.8 }, rotationX: -90, rotationZ: 180, isFlipped: false},
      {id: 'deed15', type: 'Card', deckType: 'deed', textureName:'deedCard15Texture', backTextureName: 'deedCard15BackTexture', position: { x: 1.25, y: 0, z: -11.8 }, rotationX: -90, rotationZ: 180, isFlipped: false},
      {id: 'deed16', type: 'Card', deckType: 'deed', textureName:'deedCard16Texture', backTextureName: 'deedCard16BackTexture', position: { x: 3.5, y: 0, z: -11.8 }, rotationX: -90, rotationZ: 180, isFlipped: false},
      {id: 'deed17', type: 'Card', deckType: 'deed', textureName:'deedCard17Texture', backTextureName: 'deedCard17BackTexture', position: { x: 6.5, y: 0, z: -11.8 }, rotationX: -90, rotationZ: 180, isFlipped: false},
      {id: 'deed18', type: 'Card', deckType: 'deed', textureName:'deedCard18Texture', backTextureName: 'deedCard18BackTexture', position: { x: 11.7, y: 0, z: -6.5 }, rotationX: -90, rotationZ: 90, isFlipped: false},
      {id: 'deed19', type: 'Card', deckType: 'deed', textureName:'deedCard19Texture', backTextureName: 'deedCard19BackTexture', position: { x: 11.7, y: 0, z: -4.1 }, rotationX: -90, rotationZ: 90, isFlipped: false},
      {id: 'deed20', type: 'Card', deckType: 'deed', textureName:'deedCard20Texture', backTextureName: 'deedCard20BackTexture', position: { x: 11.7, y: 0, z: -1.5 }, rotationX: -90, rotationZ: 90, isFlipped: false},
      {id: 'deed21', type: 'Card', deckType: 'deed', textureName:'deedCard21Texture', backTextureName: 'deedCard21BackTexture', position: { x: 11.7, y: 0, z: 2.7 }, rotationX: -90, rotationZ: 90, isFlipped: false},
      {id: 'deed22', type: 'Card', deckType: 'deed', textureName:'deedCard22Texture', backTextureName: 'deedCard22BackTexture', position: { x: 11.7, y: 0, z: 6.5 }, rotationX: -90, rotationZ: 90, isFlipped: false},
      {id: 'railroad1', type: 'Card', deckType: 'deed', textureName:'railRoadCard1Texture', backTextureName: 'railRoadCard1BackTexture', position: { x: 0, y: 0, z: 15 }, rotationX: -90, rotationZ: 0, isFlipped: false},
      {id: 'railroad2', type: 'Card', deckType: 'deed', textureName:'railRoadCard2Texture', backTextureName: 'railRoadCard2BackTexture', position: { x: -15, y: 0, z: 0 }, rotationX: -90, rotationZ: -90, isFlipped: false},
      {id: 'railroad3', type: 'Card', deckType: 'deed', textureName:'railRoadCard3Texture', backTextureName: 'railRoadCard3BackTexture', position: { x: 0, y: 0, z: -15 }, rotationX: -90, rotationZ: 180, isFlipped: false},
      {id: 'railroad4', type: 'Card', deckType: 'deed', textureName:'railRoadCard4Texture', backTextureName: 'railRoadCard4BackTexture', position: { x: 15, y: 0, z: 0 }, rotationX: -90, rotationZ: 90, isFlipped: false},
      {id: 'utility1', type: 'Card', deckType: 'deed', textureName:'utilityCard1Texture', backTextureName: 'utilityCard1BackTexture', position: { x: -15, y: 0, z: 5 }, rotationX: -90, rotationZ: -90, isFlipped: false},
      {id: 'utility2', type: 'Card', deckType: 'deed', textureName:'utilityCard2Texture', backTextureName: 'utilityCard2BackTexture', position: { x: 5, y: 0, z: -15 }, rotationX: -90, rotationZ: 180, isFlipped: false}
    ], isLocked: true, position: { x: -6, y: 1, z: 5 }, rotationY: 180},
  ],
  slots: [
    {
      name: 'SPACE_1',
      position: {
        x: 8.5,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_2',
      position: {
        x: 6.3,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_3',
      position: {
        x: 4.75,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_4',
      position: {
        x: 3.25,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_5',
      position: {
        x: 1.5,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_6',
      position: {
        x: 0,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_7',
      position: {
        x: -1.5,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_8',
      position: {
        x: -3,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_9',
      position: {
        x: -4.75,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_10',
      position: {
        x: -6.25,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_11',
      position: {
        x: -8.5,
        y: 0,
        z: 8.5,
      }
    },
    {
      name: 'SPACE_12',
      position: {
        x: -8.5,
        y: 0,
        z: 6.5,
      }
    },
    {
      name: 'SPACE_13',
      position: {
        x: -8.5,
        y: 0,
        z: 5,
      }
    },
    {
      name: 'SPACE_14',
      position: {
        x: -8.5,
        y: 0,
        z: 3.25,
      }
    },
    {
      name: 'SPACE_15',
      position: {
        x: -8.5,
        y: 0,
        z: 1.5,
      }
    },
    {
      name: 'SPACE_16',
      position: {
        x: -8.5,
        y: 0,
        z: 0,
      }
    },
    {
      name: 'SPACE_17',
      position: {
        x: -8.5,
        y: 0,
        z: -1.5,
      }
    },
    {
      name: 'SPACE_18',
      position: {
        x: -8.5,
        y: 0,
        z: -3,
      }
    },
    {
      name: 'SPACE_19',
      position: {
        x: -8.5,
        y: 0,
        z: -4.75,
      }
    },
    {
      name: 'SPACE_20',
      position: {
        x: -8.5,
        y: 0,
        z: -6.25,
      }
    },
    {
      name: 'SPACE_21',
      position: {
        x: -8.5,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_22',
      position: {
        x: -6.25,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_23',
      position: {
        x: -4.75,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_24',
      position: {
        x: -3.25,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_25',
      position: {
        x: -1.5,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_26',
      position: {
        x: 0,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_27',
      position: {
        x: 1.5,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_28',
      position: {
        x: 3.25,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_29',
      position: {
        x: 4.75,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_30',
      position: {
        x: 6.25,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_31',
      position: {
        x: 8.5,
        y: 0,
        z: -8.5,
      }
    },
    {
      name: 'SPACE_32',
      position: {
        x: 8.5,
        y: 0,
        z: -6.5,
      }
    },
    {
      name: 'SPACE_33',
      position: {
        x: 8.5,
        y: 0,
        z: -4.5,
      }
    },
    {
      name: 'SPACE_34',
      position: {
        x: 8.5,
        y: 0,
        z: -3.25,
      }
    },
    {
      name: 'SPACE_35',
      position: {
        x: 8.5,
        y: 0,
        z: -1.5,
      }
    },
    {
      name: 'SPACE_36',
      position: {
        x: 8.5,
        y: 0,
        z: 0,
      }
    },
    {
      name: 'SPACE_37',
      position: {
        x: 8.5,
        y: 0,
        z: 1.5,
      }
    },
    {
      name: 'SPACE_38',
      position: {
        x: 8.5,
        y: 0,
        z: 3,
      }
    },
    {
      name: 'SPACE_39',
      position: {
        x: 8.5,
        y: 0,
        z: 4.5,
      }
    },
    {
      name: 'SPACE_40',
      position: {
        x: 8.5,
        y: 0,
        z: 6.25,
      }
    }
  ]
}