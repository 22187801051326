
export default class Sizes extends EventTarget {
  constructor() {
    super()
    this.initProps()

    // resize event
    window.addEventListener('resize', () => {
      this.initProps()
      this.dispatchEvent(new Event('resize'))
    })
  }

  initProps() {
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.pixelRatio = Math.min(window.devicePixelRatio, 2)
  }
}