import * as THREE from 'three'

export default class Environment {
  constructor(experience) {
    this.experience = experience
    this.scene = this.experience.scene
    this.resourcesManager = this.experience.resourcesManager
    this.debug = this.experience.debug

    this.initLight()
    // this.initEnvironmentMap()

    if (this.debug.active) {
      this.debugFolder = this.debug.gui.addFolder('environment')
    }
  }

  initLight() {
    this.ambientLight = new THREE.AmbientLight(0xffffff)
    this.scene.add(this.ambientLight)

    this.sunLight = new THREE.DirectionalLight('#ffffff', 1.3)
    this.sunLight.castShadow = true
    this.sunLight.shadow.camera.far = 150
    this.sunLight.shadow.camera.left = 20
    this.sunLight.shadow.camera.right = -20
    this.sunLight.shadow.camera.top = 20
    this.sunLight.shadow.camera.bottom = -20
    this.sunLight.shadow.mapSize.set(1024, 1024)
    this.sunLight.shadow.normalBias = 0.05
    this.sunLight.position.set(-3, 20, 3)
    this.scene.add(this.sunLight)
    this.scene.add(this.sunLight.target)

    if (this.debug.active) {
      const helper = new THREE.DirectionalLightHelper(this.sunLight, 5 )
      this.scene.add(helper)
      this.debugFolder.add(this.sunLight.position, 'x').min(-50).max(50).name('sunX')
      this.debugFolder.add(this.sunLight.position, 'y').min(-50).max(50).name('sunY')
      this.debugFolder.add(this.sunLight.position, 'z').min(-50).max(50).name('sunZ')
      this.debugFolder.add(this.sunLight.target.position, 'x').min(-50).max(50).name('sunTargetX')
      this.debugFolder.add(this.sunLight.target.position, 'y').min(-50).max(50).name('sunTargetY')
      this.debugFolder.add(this.sunLight.target.position, 'z').min(-50).max(50).name('sunTargetZ')
      this.debugFolder.add(this.sunLight.shadow.camera, 'far').min(-5000).max(5000).name('shadowCameraFar')
      this.debugFolder.add(this.sunLight, 'intensity').min(0).max(100).name('sunIntensity')

      const shadowHelper = new THREE.CameraHelper( this.sunLight.shadow.camera )
      this.scene.add(shadowHelper)
    }
  }

  initEnvironmentMap() {
    this.environmentMap = {}
    this.environmentMap.intensity = .4
    this.environmentMap.texture = this.resourcesManager.items.environmentMapTexture
    this.environmentMap.colorSpace = THREE.SRGBColorSpace

    this.scene.environment = this.environmentMap.texture

    this.environmentMap.updateMaterial = () => {
      this.scene.traverse((child) => {
        if (child instanceof THREE.Mesh &&
            child.material instanceof THREE.MeshStandardMaterial
        ) {
          child.material.envMap = this.environmentMap.texture
          child.material.envMapIntensity = this.environmentMap.intensity
          child.material.needsUpdate = true
        }
      })
    }
    this.environmentMap.updateMaterial()

    if (this.debug.active) {
      this.debugFolder.add(
        this.environmentMap,
        'intensity'
      )
      .name('environmentMap intensity')
      .min(0).max(4)
      .onChange(this.environmentMap.updateMaterial)
    }
  }
}