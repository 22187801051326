import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/Addons'

export default class ResourcesManager extends EventTarget {
  constructor(assets) {
    super()
    this.assets = assets
    this.items = {}
    this.total = this.assets.length
    this.loaded = 0

    this.initLoaders()
    this.startLoading()
  }

  initLoaders() {
    this.loaders = {}
    this.loaders.gltfLoader = new GLTFLoader()
    this.loaders.textureLoader = new THREE.TextureLoader()
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
  }

  startLoading() {
    for (const asset of this.assets) {
      switch(asset.type) {
        case 'gltfModel':
          try {
            this.loaders.gltfLoader.load(
              asset.path,
              (file) => {
                this.assetLoaded(asset, file)
              }
            )
          } catch (error) {
            console.warn(err)
          }
          break

        case 'texture':
          this.loaders.textureLoader.load(
            asset.path,
            (file) => {
              this.assetLoaded(asset, file)
            }
          )
          break

        case 'cubeTexture':
          this.loaders.cubeTextureLoader.load(
            asset.path,
            (file) => {
              this.assetLoaded(asset, file)
            }
          )
          break

        default:
          console.warn('unknown resource type', asset.type)
      }
    }
  }

  assetLoaded(asset, file) {
    this.items[asset.name] = file
    this.loaded++
    if (this.loaded === this.total) {
      this.dispatchEvent(new Event('ready'))
    }
  }
}