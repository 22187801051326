import gsap from 'gsap'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

export default class Camera {
  constructor(experience, x, y, z) {
    this.experience = experience
    this.sizes = this.experience.sizes
    this.canvas = this.experience.canvas
    this.scene = this.experience.scene
    this.debug = this.experience.debug
    this.network = this.experience.network

    if (this.debug.active) {
      this.debugFolder = this.debug.gui.addFolder('Camera')
    }
    this.initCameraInstance(x, y, z)
    this.initOrbitControls()
  }

  initCameraInstance(x, y, z) {
    this.instance = new THREE.PerspectiveCamera(
      35,
      this.sizes.width/this.sizes.height,
      0.1,
      100
    )
    this.instance.position.set(x || 0, y || 18, z || 30)
    this.scene.add(this.instance)

    if (this.debug.active) {
      this.debugFolder.add(this.instance.position, 'x').min(-5).max(50).name('cameraX')
      this.debugFolder.add(this.instance.position, 'y').min(-5).max(50).name('cameraY')
      this.debugFolder.add(this.instance.position, 'z').min(-5).max(50).name('cameraZ')
    }

    this.network.addEventListener('cameraPosition', (event) => {
      const { x, y, z } = event.detail
      this.instance.position.set(x, y, z)
    });
  }

  initOrbitControls() {
    this.controls = new OrbitControls(this.instance, this.canvas)
    this.controls.enableDamping = true
    //this.controls.enablePan = false
    this.controls.maxDistance = 50
    this.controls.minDistance = 10
    this.controls.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.PAN
    }

    this.controls.addEventListener('end', async ()=> {
      if (this.experience.enableOnline && this.experience.playerId) {
        await this.network.updateDocument('play_users', this.experience.playerId, {
          cameraX: Math.round(this.instance.position.x),
          cameraY: Math.round(this.instance.position.y),
          cameraZ: Math.round(this.instance.position.z),
        })
      }
    })

    if (!this.debug.active) {
      this.controls.maxPolarAngle = Math.PI * .33
    }
  }

  animateTo(x, y, z) {
    gsap.to(this.instance.position, {
      x,
      y,
      z,
      duration: 1.5,
      onUpdate: () => {
        this.instance.lookAt(0, 0, 0)
      }
    })
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height
    this.instance.updateProjectionMatrix()
  }

  update() {
    this.controls.enableRotate = !this.experience.isDragging
    this.controls.update()
  }
}