export function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  )
}

export function rand(min, max) {
  return Math.random() * (max - min) + min
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function isDesktop() {
  return !isMobile()
}

const eps = .1
export function isZero(angle) { return Math.abs(angle) < eps }
export function isHalfPi(angle) { return Math.abs(angle - .5 * Math.PI) < eps }
export function isMinusHalfPi(angle) { return Math.abs(.5 * Math.PI + angle) < eps }
export function isPiOrMinusPi(angle) { return (Math.abs(Math.PI - angle) < eps || Math.abs(Math.PI + angle) < eps) }